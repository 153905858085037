import './App.css';
import Contenido from './components/Contenido/Contenido';

function App() {
  return (
    <div className="App">
      <Contenido />
    </div>
  );
}

export default App;

