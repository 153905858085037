import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components'

const theme = {
    background: '#f5f8fb',
    headerBgColor: 'navy',
    headerFontColor: '#fff',
    headerFontSize: '20px',
    botBubbleColor: 'navy',
    botFontColor: '#fff',
    userBubbleColor: '#0cb3c9',
    userFontColor: '#fff',
}

const valueMap = {
    'nombre': 'Nombre',
    'motivo': 'Motivo',
    'tipoEcografia': 'Tipo ecografía',
    'barrioEcoDoppler': 'Barrio',
    'zonaEcoDoppler': 'Zona ecografía',
    'lugarTurno': 'Lugar',
    'barrio': 'Barrio',
    'consultorio': 'Consultorio',
    'nombrePreferencias': 'Datos',
    'otroLugar': 'Otro lugar',
    'lugarRadiografia': 'Lugar Rx',
    'estudio': 'Estudio',
    'motivoOtras': 'Otros motivos',
    'canceladatos': 'Aclaraciones',
};

class Review extends Component {
    constructor(props) {
        super(props);

        this.state = {
            'nombre': '',
            'motivo': '',
            'tipoEcografia': '',
            'barrioEcoDoppler': '',
            'zonaEcoDoppler': '',
            'lugarTurno': '',
            'barrio': '',
            'consultorio': '',
            'nombrePreferencias': '',
            'otroLugar': '',
            'lugarRadiografia': '',
            'estudio': '',
            'motivoOtras': '',
            'canceladatos': '',
        };
    }

    componentWillMount() {
        const { steps } = this.props;
        const { motivo, nombre, tipoEcografia, barrioEcoDoppler, zonaEcoDoppler, lugarTurno, barrio, consultorio, nombrePreferencias, otroLugar, lugarRadiografia, estudio, motivoOtras, canceladatos } = steps;

        this.setState({ motivo, nombre, tipoEcografia, barrioEcoDoppler, zonaEcoDoppler, lugarTurno, barrio, consultorio, nombrePreferencias, otroLugar, lugarRadiografia, estudio, motivoOtras, canceladatos });
    }

    render() {
        const { motivo, nombre, tipoEcografia, barrioEcoDoppler, zonaEcoDoppler, lugarTurno, barrio, consultorio, nombrePreferencias, otroLugar, lugarRadiografia, estudio, motivoOtras, canceladatos } = this.state;
        console.log('render state', this.state)

        const tableRows = Object.entries(this.state).map(([key, value]) => {
            if (value !== undefined && valueMap[key]) {
                return (
                    <tr key={key}>
                        <td><b>{valueMap[key]}:</b></td>
                        <td>{value.value}</td>
                    </tr>
                );
            }
            return null;
        });

        const stateEntries = Object.entries(this.state);
        const whatsappMessageParts = stateEntries
            .filter(([key, value]) => value && value.value !== undefined)
            .map(([key, value]) => `${valueMap[key]}: ${value.value}`);

        const whatsappMessage = "Hola, deseo solicitar un turno:\n\n" + whatsappMessageParts.join('\n');


        return (
            <div style={{ width: '100%' }}>
                <h3 style={{ marginTop: '5px', fontSize: '16px'}}>Envíe los datos a nuestro operador y espere la confirmación del turno:</h3>
                <table>
                    <tbody>
                        {tableRows}
                    </tbody>
                </table>
                <div style={{background: "#fff", padding: "10px", textAlign: "center"}}>
                    <a href={`https://wa.me/5491154525043?text=${encodeURIComponent(whatsappMessage)}`} target="_blank" rel="noopener noreferrer">
                        Enviar datos al operador
                    </a>
                </div>
            </div>

        );
    }
}
Review.propTypes = {
    steps: PropTypes.object,
};

Review.defaultProps = {
    steps: undefined,
};

class SimpleForm extends Component {
    render() {
        return (
            <ThemeProvider theme={theme}>
            <ChatBot
                headerTitle="Turnos DEVED"
                placeholder="Escribe un mensaje..."
                steps={[
                    {
                        id: "1",
                        message: "Bienvenido a DEVED, ¿Podria indicarme su nombre?",
                        trigger: "nombre",
                    },
                    {
                        id: "nombre",
                        user: true,
                        trigger: "3",
                    },
                    {
                        id: "3",
                        message: "Hola {previousValue}",
                        trigger: "4",
                    },
                    {
                        id: "4",
                        message: "Muchas gracias por comunicarse con DEVED. Por favor indique el motivo por el cual nos contacta",
                        trigger: "motivo"
                    },
                    {
                        id: "motivo",
                        options: [
                            { value: "Turno Ecografía", label: "Turno Ecografía", trigger: "motivo-ecografia" },
                            { value: "Turno Eco Doppler", label: "Turno Eco Doppler", trigger: "barrioEcoDoppler" },
                            { value: "Turno Radiografía", label: "Turno Radiografía", trigger: "lugarRadiografia" },
                            { value: "Otras opciones", label: "Otras opciones", trigger: "motivoOtras" },
                        ]
                    },
                        {
                            id: "motivo-ecografia",
                            message: "Seleccione el tipo de estudio que desea realizar",
                            trigger: "tipoEcografia"
                        },
                    {
                        id: "lugarRadiografia",
                        options: [
                            { value: "Veterinaria PET’S (Palermo)", label: "Córdoba 4416 Veterinaria PET’S (Palermo)", trigger: "avcordoba" },
                            { value: "Veterinaria Merli (Villa Crespo)", label: "Apolinario Figueroa 299 Veterinaria Merli (Villa Crespo)", trigger: "apolinario" },
                        ]
                    },
                        {
                            id: "avcordoba",
                            message: "Los horarios de atención para Rx son lunes, martes, miércoles y viernes de 16 a 19 hs, si desea continuar le pedimos que mencione el estudio a realizar. Muchas Gracias",
                            trigger: "estudio"
                        },
                        {
                            id: "apolinario",
                            message: "Los horarios de atención son de lunes a viernes de 16,30 a 19 hs y jueves y sábados a la mañana, si desea continuar le pedimos que mencione el estudio a realizar",
                            trigger: "estudio"
                        },
                            {
                                id: "estudio",
                                user: true,
                                trigger: "operador",
                            },
                    // {
                    //     id: "adjuntafoto",
                    //     user: true,
                    //     message: "Le pedimos por favor adjunte foto de la orden del estudio para saber que estudio radiográfico e incidencias le piden. Si Ud. no tiene la orden puede mencionar a continuación que estudios le solicitan.",
                    // },
                    {
                        id: "motivoOtras",
                        options: [
                            { value: "Cancelar turno", label: "Cancelación de turno", trigger: "cancela" },
                            { value: "Cancelar y reprogramar", label: "Cancelación y reprogramación de un turno", trigger: "cancelayrepro" },
                            { value: "No recibí el informe", label: "Pasaron 24 hs y no recibí el informe", trigger: "pasaron24" },
                            { value: "Preparación estudios", label: "Preparación para los diferentes estudios", trigger: "preparacion" },
                            { value: "Formas de pago", label: "Formas de pago", trigger: "formasdepago" },
                        ]
                    },
                    {
                        id: "cancela",
                        message: "Por favor especifique: Apellido, Día, Horario y Lugar donde se le iba a realizar el estudio",
                        trigger: "canceladatos"
                    },
                    {
                        id: "cancelayrepro",
                        message: "Por favor especifique: Apellido, Día, Horario y Lugar donde se le iba a realizar el estudio y para cuando quisiera hacer nuevamente el estudio",
                        trigger: "canceladatos"
                    },
                    {
                        id: "pasaron24",
                        message: "Por favor especifique: Apellido, Día, Horario, Lugar donde se le iba a realizar el estudio y su Email.",
                        trigger: "canceladatos"
                    },
                    {
                        id: "preparacion",
                        message: "La preparación para el estudio ecográfico es:",
                        trigger: "preparacion01"
                    },
                        {
                            id: "preparacion01",
                            message: "- Ayuno de alimentos sólidos de como mínimo 4 hs",
                            trigger: "preparacion02"
                        },
                        {
                            id: "preparacion02",
                            message: "- Puede beber el agua que desee",
                            trigger: "preparacion03"
                        },
                        {
                            id: "preparacion03",
                            message: "- No debe orinar 1 hs antes del estudio. Para eso si es un felino debe esconder el baño sanitario y si es un canino debe restringir el acceso a los lugres donde normalmente hace pis (en la medida de lo posible)",
                            trigger: "preparacion04"
                        },
                        {
                            id: "preparacion04",
                            message: "- Le solicitamos por favor lleve la orden médica para orientar al profesional que realizara el estudio",
                            trigger: "preparacion05"
                        },
                        {
                            id: "preparacion05",
                            message: "- Si se encuentra tomando alguna medicación puede administrársela en la forma habitual",
                            trigger: "gracias"
                        },
                    {
                        id: "formasdepago",
                        message: "Las honorarios pueden abonarse de las siguientes maneras:",
                        trigger: "formasdepago01"
                    },
                        {
                            id: "formasdepago01",
                            message: "- Efectivo",
                            trigger: "formasdepago02"
                        },
                        {
                            id: "formasdepago02",
                            message: "- Tarjeta de debito",
                            trigger: "formasdepago03"
                        },
                        {
                            id: "formasdepago03",
                            message: "- Dinero en cuenta de Mercado Pago",
                            trigger: "formasdepago04"
                        },
                        {
                            id: "formasdepago04",
                            message: "- Transferencia bancaria realizada al momento del estudio",
                            trigger: "gracias"
                        },
                    {
                        id: "canceladatos",
                        user: true,
                        trigger: "operador",
                    },                    {
                        id: "gracias",
                        message: "Muchas gracias.",
                        end: true,
                    },
                    {
                        id: "tipoEcografia",
                        options: [
                            { value: "Ecografía abdominal", label: "Ecografía abdominal", trigger: "mensaje-lugarTurno" },
                            { value: "Ecografía de tiroides", label: "Ecografía de tiroides", trigger: "mensaje-lugarTurno" },
                            { value: "Ecografía ocular", label: "Ecografía ocular", trigger: "mensaje-lugarTurno" },
                            { value: "Ecografía de partes blandas", label: "Ecografía de partes blandas", trigger: "mensaje-lugarTurno" },
                            { value: "Ecografía de tórax no cardíaco", label: "Ecografía de tórax no cardíaco", trigger: "mensaje-lugarTurno" },
                            { value: "Ecografía transcraneana", label: "Ecografía transcraneana", trigger: "mensaje-lugarTurno" },
                            { value: "Ecografía de abdomen y otro sector", label: "Ecografía de abdomen y otro sector", trigger: "mensaje-lugarTurno" },
                        ],
                    },
                    // {
                    //     id: "7A",
                    //     message: "Ha seleccionado ecografía abdominal",
                    // },
                    {
                        id: "barrioEcoDoppler",
                        options: [
                            { value: "Villa Crespo", label: "Villa Crespo", trigger: "zonaEcoDoppler" },
                            { value: "Villa del Parque", label: "Villa del Parque", trigger: "zonaEcoDoppler" },
                            { value: "Palermo", label: "Palermo", trigger: "zonaEcoDoppler" },
                            { value: "Belgrano", label: "Belgrano", trigger: "zonaEcoDoppler" },
                            { value: "Núñez", label: "Núñez", trigger: "zonaEcoDoppler" },
                        ],
                    },
                    {
                        id: "zonaEcoDoppler",
                        options: [
                            { value: "Abdomen", label: "Abdomen", trigger: "operador" },
                            { value: "Riñón", label: "Riñón", trigger: "operador" },
                            { value: "Hígado", label: "Hígado", trigger: "operador" },
                            { value: "Transcraneano", label: "Transcraneano", trigger: "operador" },
                            { value: "Tiroides", label: "Tiroides", trigger: "operador" },
                            { value: "Otro", label: "Otro", trigger: "operador" },
                        ]
                    },
                    // {
                    //     id: "7H",
                    //     message: " Por favor indique qué estudio de abdomen y otro sector le solicitaron ",
                    //     trigger: "abdomenyotro"
                    // },
                    // {
                    //     id: "abdomenyotro",
                    //     options: [
                    //         { value: "Abdomen y tiroides", label: "Abdomen y tiroides" },
                    //         { value: "Abdomen y tórax", label: "Abdomen y tórax" },
                    //         { value: "Abdomen y ojo", label: "Abdomen y ojo" },
                    //         { value: "Abdomen y eco cardio", label: "Abdomen y eco cardio" },
                    //         { value: "Abdomen y Transcraneano", label: "Abdomen y Transcraneano" },
                    //         { value: "Abdomen y partes blandas", label: "Abdomen y partes blandas" },
                    //         { value: "Orden Médica", label: "Si posee orden médica adjunte foto, y si tiene preferencia con algún profesional indique su nombre" },
                    //     ]
                    // },

                    {
                        id: "mensaje-lugarTurno",
                        message: "Seleccione el lugar donde desea hacer el estudio",
                        trigger: "lugarTurno",
                    },
                    {
                        id: "lugarTurno",
                        options: [
                            { value: "Domicilio", label: "Domicilio", trigger: "mensaje-barrio" },
                            { value: "Consultorio", label: "Consultorio", trigger: "mensaje-consultorio" },
                            { value: "Otras opciones", label: "Otras Opciones", trigger: "mensaje-otroLugar" },
                        ],
                    },
                    {
                        id: "mensaje-barrio",
                        message: "Indique en qué barrio vive",
                        trigger: "barrio",
                    },
                    {
                        id: "barrio",
                        user: true,
                        trigger: "operador",
                    },
                    {
                        id: "mensaje-consultorio",
                        message: "¿En qué veterinaria se atiende o qué doctor le indica el estudio?",
                        trigger: "consultorio",
                    },
                    {
                        id: "mensaje-otroLugar",
                        message: "¿En qué veterinaria se atiende o qué doctor le indica el estudio?",
                        trigger: "otroLugar",
                    },
                    {
                        id: "otroLugar",
                        user: true,
                        trigger: "operador",
                    },

                    {
                        id: "consultorio",
                        options: [
                            { value: "Villa crespo", label: "Villa crespo", trigger: "vcrespo" },
                            { value: "Palermo", label: "Palermo", trigger: "palermo" },
                            { value: "Villa del Parque", label: "Villa del Parque", trigger: "vdelparque" },
                            { value: "Belgrano", label: "Belgrano", trigger: "belgrano" },
                            { value: "Villa Pueyrredón", label: "Villa Pueyrredón", trigger: "vpueyrredon" },
                            { value: "Villa Santa Rita", label: "Villa Santa Rita", trigger: "vsantarita" },
                            { value: "Mataderos", label: "Mataderos", trigger: "mataderos" },
                            { value: "Almagro", label: "Almagro", trigger: "almagro" },
                            { value: "Núnez", label: "Núnez", trigger: "nunez" },
                        ],
                    },
                    {
                        id: "vcrespo",
                        message: "Los horarios de atención son: lunes , martes , jueves y viernes de 16 a 20 hs y miércoles de 12 a 14 hs",
                        trigger: "confirmarTurno",
                    },
                    {
                        id: "palermo",
                        message: "Los horarios de atención son: lunes , martes, miércoles y viernes de 16 a 19 hs . Jueves de 10 a 12 hs",
                        trigger: "confirmarTurno",
                    },
                    {
                        id: "vdelparque",
                        message: "Los horarios de atención son: martes de 15 a 17 hs , jueves y viernes de 16 a 20hs",
                        trigger: "confirmarTurno",
                    },
                    {
                        id: "belgrano",
                        message: "Los horarios de atención son Viernes de 16 a 19 hs y sábados de 10 a 13 hs ",
                        trigger: "confirmarTurno",
                    },
                    {
                        id: "vpueyrredon",
                        message: "Los horarios de atención son:  lunes 14 a 16 hs y jueves de 16 a 18 hs",
                        trigger: "confirmarTurno",
                    },
                    {
                        id: "vsantarita",
                        message: "Los horarios de atención son:  martes 16 a 18 hs y sábados de 10 a 13 hs",
                        trigger: "confirmarTurno",
                    },
                    {
                        id: "mataderos",
                        message: "Los horarios de atención son: jueves de 15 a 18 hs",
                        trigger: "confirmarTurno",
                    },
                    {
                        id: "almagro",
                        message: "Los horarios de atención son: Almagro martes jueves y sábados (horarios a convenir",
                        trigger: "confirmarTurno",
                    },
                    {
                        id: "nunez",
                        message: "Los horarios de atención son: de 18 a 18.30 hs ",
                        trigger: "confirmarTurno",
                    },
                    {
                        id: "confirmarTurno",
                        options: [
                            { value: "30", label: "Continuar para confirmar turno", trigger: "datos-personales" },
                            { value: "31", label: "Volver a seleccionar otro consultorio", trigger: "consultorio" },
                        ],
                    },
                    {
                        id: "datos-personales",
                        message: "Por favor indique su nombre y apellido, día y horario de preferencia de turno",
                        trigger: "nombrePreferencias"
                    },
                    {
                        id: "nombrePreferencias",
                        user: true,
                        trigger: "operador"
                    },
                    {
                        id: 'operador',
                        component: <Review />,
                        end: true,
                    },
                ]}
            />
            </ThemeProvider>
        );
    }
}

export default SimpleForm;